export default class {

    constructor(config) {
        // set config
        this.config = config !== undefined ? config : {};

        // wait for dom loaded
        document.addEventListener('DOMContentLoaded', (e) => {

            // init all modules
            this.checkAll();

            // init elements added with collection
            document.addEventListener('collection_add', (e) => {
                this.checkAll(e.target);
            });

        });
    }

    checkAll(context) {
        // set context to whole document if not specified
        if (!context) {
            context = document;
        }

        // init form collection
        this.init('.FormCollection', 'form-collection', context);

        // init form file upload
        this.init('.FormFile', 'form-fileupload', context);

        // init form fieldset
        this.init('.FormFieldset [data-dependencies]', 'form-fieldset', context);

        // init teaser slider
        this.init('[data-teaser-slider]', 'teaser-slider', context);

        // init lazyload
        this.init('[data-src]', 'image-lazyload', context);
    }

    // init js module
    init(selector, file, context) {
        // get elements
        let elements = context.querySelectorAll(selector);
        if (elements.length) {

            // import module
            import(
                /* webpackChunkName: "tenor" */
                /* webpackMode: "lazy" */
            'tenor/' + file).then(module => {
                module.default.init(elements, this.config);
            });
        }
    }
}